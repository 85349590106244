import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 10 min. `}<meta itemProp="prepTime" content="PT10M" /></h5>
    <h5>{`Cook time: 30 min. `}<meta itemProp="cookTime" content="PT30M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`6 pieces`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Flour, 1 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Mix-ins (e.g. blueberries), 1-1.5 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sugar, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt, 1/4 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Baking powder, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Milk, 1 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Egg, 1</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter or vegetable oil</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Large bowl</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Large cast iron skillet or frying pan</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Ladle</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Serving plate</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Chop any mix-ins, as needed</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Add flour to large bowl; stir mix-ins into flour.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add sugar, salt, and baking powder; stir.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add milk and eggs and stir; do not whisk mixture.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Grease the skillet with butter or vegetable oil; heat the pan.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Pour half ladles of batter into the pan to form as many pancakes as will fit.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cook until edges begin to color and small bubbles appear all over surface.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Flip pancakes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cook for a few moments on other side and slide onto serving plate.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Keep warm until all pancakes are cooked.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      